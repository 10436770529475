import { useEffect } from "react";

import { createWallet } from "../utils/accounts";
import { sendMessage } from "../utils/misc";
import useAccountsData from "./useAccountsData";
import { useNetworks } from "../context/NetworksContext";

const useGetOrCreateAccounts = () => {
  const { networksData } = useNetworks();
  const { getAccountsData } = useAccountsData();

  useEffect(() => {
    const handleCreateAccounts = async (event: MessageEvent) => {
      if (event.data.type !== 'REQUEST_CREATE_OR_GET_ACCOUNTS') return;

      let accountsData = await getAccountsData(event.data.chainId);

      if (accountsData.length === 0) {
        console.log("Accounts not found, creating wallet...");
        await createWallet(networksData);

        // Re-fetch newly created accounts
        accountsData = await getAccountsData(event.data.chainId);
      }

      sendMessage(
        event.source as Window, 'WALLET_ACCOUNTS_DATA',
        accountsData.map(account => account.address),
        event.origin
      );
    };

    window.addEventListener('message', handleCreateAccounts);

    return () => {
      window.removeEventListener('message', handleCreateAccounts);
    };
  }, [networksData, getAccountsData]);
};

export default useGetOrCreateAccounts;
